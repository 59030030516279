import React, { useEffect, useRef } from 'react';
import { Media, MediaContextProvider } from '../media';

export const NO_IMAGE = '/assets/images/no-image.svg'

export default function Image({ src, alt, desktop, mobile, noImage, preferredWidth, preferredHeight, delay, ...restProps }) {

  const imgRef = useRef(null);
  // const mdBreakpoint = 768;
  let adjustedProps = {
    alt,
    style: {
      minWidth: preferredWidth,
      minHeight: preferredHeight
    },
    onError: () => {
      imgRef.current.setAttribute('src', noImage)
    },
    ...restProps
  }

  useEffect(() => {
    const src = imgRef.current?.getAttribute('src')
    if (src?.endsWith(noImage)) {
      const { width, height } = restProps
      if (width || height) {
        let cssText = ''
        if (width) {
          cssText += `width: ${width}px !important;`
        }
        if (height) {
          cssText += `height: ${height}px !important;`
        }
        imgRef.current.setAttribute('style', cssText)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <MediaContextProvider>
      <Media lessThan="md">
        <img
          src={mobile || src || noImage}
          ref={imgRef}
          alt={alt}
          // loading="lazy"
          {...adjustedProps}
        />
      </Media>
      <Media greaterThanOrEqual="md">
        <img
          src={desktop || src || noImage}
          ref={imgRef}
          alt={alt}
          // loading="lazy"
          {...adjustedProps}
        />
      </Media>
    </MediaContextProvider>
      // <img
      //   src={window.innerWidth >= mdBreakpoint ? desktop || src || noImage : mobile || src || noImage}
      //   ref={imgRef}
      //   // loading="lazy"
      //   {...adjustedProps}
      // />
  )

}

Image.defaultProps = {
  alt: '',
  noImage: NO_IMAGE,
  preferredWidth: undefined,
  preferredHeight: undefined,
  delay: 0
}