import React from "react";
// import "./modalDowmload.scss";
import Image from "../../../components/image";
import { asset } from "../../../helpers/dom.helper";
import './modalSuccess.scss';
import { Media, MediaContextProvider } from "../../../media";

function ModalSuccess(props) {

  return (
    props.isModalVisible  &&
    <>
      <MediaContextProvider>
        <Media greaterThanOrEqual="md">
            <div id="successFully">
                <div className="modalContainer">
                    <h2>Send message successfully</h2>
                    <Image            
                        className="cross"
                        alt='cross'
                        src={asset('/icon/cross.svg')}                  
                        width="25"
                        height="25"
                        onClick={props.onModalClose}
                    />
                </div>
            </div>
        </Media>
        <Media lessThan="md">
            <div id="successFullyMobile">
                <div className="modalContainer">
                    <h2>Send message successfully</h2>
                    <Image            
                        className="cross"
                        alt='cross'
                        src={asset('/icon/cross.svg')}                  
                        width="25"
                        height="25"
                        onClick={props.onModalClose}
                    />
                </div>
            </div>
        </Media>
      </MediaContextProvider>
    </>
  );
}

export default ModalSuccess;
