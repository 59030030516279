import React from 'react';
import { asset } from '../helpers/dom.helper';
import { Footer } from './footer';
import { Navigation } from './navigation';
import LazyLoad from 'react-lazyload';

export class DefaultLayout extends React.Component {
  render() {

    return (
      <>
          <Navigation />                
          <div className="container-inner" style={{
            '--bg-header': `url(${asset('/images/home/header-background.svg')})`,
            '--icon-double-quote-start': `url(${asset('/icon/double-quote-start.webp')})`,
            '--icon-double-quote-end': `url(${asset('/icon/double-quote-end.webp')})`,
            '--icon-play': `url(${asset('/icon/play.svg')})`,
            '--icon-search': `url(${asset('/icon/search.svg')})`,
            '--icon-dropdown-blue': `url(${asset('/images/navigation/dropdown-blue.webp')})`,
          }}>
            {this.props.children}
          </div>                 
          <LazyLoad once>
            <Footer />
          </LazyLoad>      
      </>
    )
  }

}