import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import { toRoute } from "../../i18n";
import { Media, MediaContextProvider } from '../../media';
import { MobileNavigation } from "./mobileNavigation";
import './style.scss';
import { NAVIGATION_DATA } from "./_data";

const DropdownSubmenu = React.memo(({ parent, items }) => {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const [isEnter, setIsEnter] = useState(false)

	return (
		<div
			className="dropdown-submenu"
			onMouseEnter={() => setIsEnter(true)}
			onMouseLeave={() => setIsEnter(false)}
		>
			<Dropdown.Item as={Link} to={pathname} style={parent.style || {}} className="subMenuContainer">
			{t(parent.label)}
			<span className="arrow-container">
				<img
				alt="right-arrow"
				width={"11px"}
				height={"11px"}
				className="normal-image"
				src={asset(`/images/navigation/right-arrow.svg`)}
				/>
				<img
				alt="right-arrow-bold"
				width={"11px"}
				height={"11px"}
				className="bold-image"
				src={asset(`/images/navigation/right-arrow-bold.svg`)}
				/>
			</span>
			</Dropdown.Item>
			<div className={`dropdown-menu dropdown-menu-left subDropsdown ${isEnter ? 'show' : ''}`}>
				{items?.map((it, idx) => (
					<Link to={toRoute(it.path)} className="dropdown-item" key={idx}>{t(it.label)}</Link>
				))}
			</div>
		</div>
	)
})



export function Navigation(props) {

	const { t } = useTranslation()
	const { pathname } = useLocation()
	// const [getIndex, setGetIndex] = useState(0);

	// function receiveIndex(index) {
	//   setGetIndex(index);
	// }

	  function scrollToSendUsMessage() {
		const sendUsMessage = document.getElementById('sendUsMessage');
		if (sendUsMessage) {
		  const offset = 80;
		  const top = sendUsMessage.getBoundingClientRect().top + window.scrollY - offset;
		  window.scrollTo({
			top,
			behavior: 'smooth'
		  });
		}
	  }

	// const langaugeAll = [
	// 	{
	// 		"lang": "en",
	// 		"langImage": "/images/navigation/langauge/desktop/eng.svg",
	// 	}
	// ]	

	// function getUrl(lang) {
	// 	if (lang === 'en') {
	// 		return '/en' + pathname.replace(/^\/en\//, '/')
	// 	}
	// 	return pathname.replace(/^\/en\//, '/')
	// }

	function hoverNavBar(path) {
		if(pathname.includes(path)) {
			if(path === "/about-us" && pathname.includes("sustainability")) {
				return false
			}
			if(path === "/packaging" && pathname.includes("blog")) {
				return false
			}
			return true
		} else {
			return false
		}
	}

	return (
		<div id="navigation" className="white">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<nav className="navbar navbar-expand-lg navbar-light">
						<Link to={toRoute("/")}>
							<img src={asset('/images/navigation/LussopackLogo.svg')} alt="lussopack" width="145" height="42" className='logo'/>										
						</Link>

							
							<div className='d-flex flex-row align-items-center'>
								{/* <div className="switch-language d-flex flex-row mr-2"> */}
									{/* <div className="dropdown"> */}
									{/* <button
										className="dropdown-toggle selectedLangToggle"
										type="button"
										// id="dropdownMenuButton"
										data-mdb-toggle="dropdown"
										aria-expanded="false"
									>	
										<Link to={toRoute(getUrl(`${langaugeAll[getIndex].lang}`))}>
											<img 
												src={asset(`${langaugeAll[getIndex].langImage}`)}
												alt='langBtn'
												width="25px"
												height="25px"											
											/>
										</Link>
									</button> */}
									{/* <ul className="dropdown-menu selectedLang" aria-labelledby="dropdownMenuButton">
										<li>
										{
										langaugeAll.map((lang, index)=> (
											<Link key={index} to={getUrl(lang.lang)}>
												<img 
													src={asset(`${lang.langImage}`)}
													alt='lang'
													width="25px"
													height="25px"
													onClick={()=>receiveIndex(index)}
												/>																								
											</Link>
											))
										}
										</li>
									</ul> */}
									{/* </div> */}
								{/* </div> */}

								<MediaContextProvider>
									<Media lessThan="md">
										<MobileNavigation/>
									</Media>
								</MediaContextProvider>
							</div>

							<div className="collapse navbar-collapse justify-content-end">
								<div className="navbar-nav justify-content-between align-items-center">

								{NAVIGATION_DATA?.map((nav, key) => (
								<div key={key} className="dropdown">
									<Link to={toRoute(nav.path)}>
										<button
											className={`${hoverNavBar(nav.path) ? "dropdown-toggle-active" : "dropdown-toggle"}`}
											type="button"
											data-mdb-toggle="dropdown"
											aria-expanded="false"
										>	
											{t(nav.label)}
											{nav.items && <Image src={asset(`${hoverNavBar(nav.path) ? "/images/navigation/dropdownBold.svg" : "/images/navigation/dropdown.svg"}`)} alt="dropdown-icon" width="11" height="11" className="dropdown-icon" />}
										</button>
									</Link>
									{nav.items &&
									<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{"top":"22px"}}>
										{nav.items?.map((it, idx) => (
											it.items?.length
												? <DropdownSubmenu parent={it} items={it.items} key={`${key}${idx}`} />
												: 
												<li key={`${key}${idx}`}><Link className="dropdown-item" to={toRoute(it.path)}>{t(it.label)}</Link></li>
											))}												
									</ul>}
								</div>
								))}
										<div className="nav-link text-right">
											<button className="registration" onClick={()=>scrollToSendUsMessage()}>												
												{t("Request a quote")}
											</button>
										</div>

								</div>
							</div>

						</nav>
					</div>
				</div>
			</div >
		</div >
	)

}
