import React from 'react';
import { Route } from 'react-router-dom';
import { ContentLayout } from './ContentLayout';

export function ContentRoute({ component: Component, ...rest }) {

  return (
    <Route
      {...rest}
      render={routeProps => (
        <ContentLayout>
          <Component {...routeProps} />
        </ContentLayout>
      )}
    />
  )

}
