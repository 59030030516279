import { loadableReady } from "@loadable/component";
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import ReactDOM from 'react-dom';
import { useSSR } from "react-i18next";
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import { registerStore } from './store';

if (process.env.NODE_ENV !== 'development') {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}

//
// Create Redux store with state injected by the server
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization.
// Allow the passed state to be garbage-collected
const store = registerStore(window.__PRELOADED_STATE__)
setupListeners(store.dispatch)
delete window.__PRELOADED_STATE__

function WebApp({ initialI18nStore, initialLanguage }) {
  useSSR(initialI18nStore, initialLanguage)

  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  )
}

loadableReady(() => {
  const initialI18nStore = { ...window.__initialI18nStore__ }
  const initialLanguage = window.__initialLanguage__
  delete window.__initialI18nStore__
  delete window.__initialLanguage__

  ReactDOM.hydrate(
    <WebApp initialI18nStore={initialI18nStore} initialLanguage={initialLanguage} />,
    document.getElementById('root')
  )
})
