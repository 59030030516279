import React from 'react';
// import { useTranslation } from "react-i18next";
import { useState } from 'react';
import './sendUsMessage.scss'
import { asset } from "../../helpers/dom.helper";
import Image from "../../components/image";
import { Media, MediaContextProvider } from '../../media';
import { COUNTRY } from './countryJson';
import SearchableDropdown from './searchDropdown';
import ModalSuccess from './modalSuccess/modalSuccess';

const SendUsMessage = React.memo(() => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputPhoneNumber, setInputPhoneNumber] = useState('');
  const [inputCompanyName, setInputCompanyName] = useState('');
  const [inputRequest, setInputRequest] = useState('');
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const raw = JSON.stringify({
    "first_name": inputFirstName,
    "last_name": inputLastName,
    "email": inputEmail,
    "phone": inputPhoneNumber,
    "company_name": inputCompanyName,
    "county": selectedCountry,
    "request": inputRequest,
  });

  const handleInputChange = (e, selectInput) => {
    selectInput(e.target.value);
  };

  const sendMessage = () => {
    if (
        inputFirstName &&
        inputLastName &&
        inputEmail &&
        inputPhoneNumber &&
        inputCompanyName &&
        selectedCountry
    ) {
    
        const tokenUrl = "https://lussopack-backend-app-bfyz8.ondigitalocean.app/generate_token";
        const tokenHeaders = new Headers();
        tokenHeaders.append("Content-Type", "application/json");

        const tokenRequestBody = JSON.stringify({
            username: "lussopack",
            password: "108689lussopack"
        });

        const tokenOptions = {
            method: 'POST',
            headers: tokenHeaders,
            body: tokenRequestBody,
            redirect: 'follow'
        };

        fetch(tokenUrl, tokenOptions)
            .then(response => response.json())
            .then(tokenResult => {
                const authToken = tokenResult.token;


                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${authToken}`);      
                
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                
                fetch("https://lussopack-backend-app-bfyz8.ondigitalocean.app/insert_email", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.message === "Email inserted and HTML email sent successfully"){
                        showModal()
                    }
                })
                .catch(error => console.log('error', error));
            })
            .catch(tokenError => console.log('error generating token', tokenError));
        }
  };

  return (
    <MediaContextProvider>
        <Media greaterThanOrEqual="md">
            <div id="sendUsMessage">   
                <div className='messageContainer'>
                    <div className='headerMessage'>
                        <Image
                            className='emailIcon'              
                            alt='emailIcon'
                            src={asset('/images/footer/sendUsMessage/desktop/emailIcon.svg')}                  
                            width="40"
                            height="40"
                        />                                          
                        <h2>
                            Send us a message :
                        </h2>
                    </div>
                    <p>Fill out our form and we will contact you within 24 hours.</p>
                </div>   
                <div className='inputContainer'>
                    <div className='inputList'>                
                        <input 
                            type='text'
                            placeholder='First Name *'
                            value={inputFirstName}   
                            autoComplete="nope"                     
                            onChange={(e) => handleInputChange(e, setInputFirstName)}
                        />
                        <input
                            type='text'
                            placeholder='Last Name *'
                            value={inputLastName}    
                            autoComplete="nope"                    
                            onChange={(e) => handleInputChange(e, setInputLastName)}
                        />
                    </div>
                    <div className='inputList'>
                    <input
                        type='email'
                        placeholder='Email *'
                        value={inputEmail}         
                        autoComplete="nope"           
                        onChange={(e) => handleInputChange(e, setInputEmail)}
                    />
                    <input
                        type='tel'
                        placeholder='Phone number *'
                        value={inputPhoneNumber} 
                        autoComplete="nope"                   
                        onChange={(e) => handleInputChange(e, setInputPhoneNumber)}
                    />
                    </div>
                    <div className='inputList'>
                        <input 
                            type='text'
                            placeholder='Company Name *'
                            value={inputCompanyName}  
                            autoComplete="nope"                      
                            onChange={(e) => handleInputChange(e, setInputCompanyName)}
                        />
                    <SearchableDropdown 
                            options={COUNTRY}
                            label="name"
                            id="id"
                            selectedVal={selectedCountry}
                            handleChange={(val) => setSelectedCountry(val)}                    
                    />
                    </div>
                    <div className='textareaList'>
                        <textarea 
                            type='text'
                            placeholder='Requests *'   
                            value={inputRequest}    
                            autoComplete="nope"                                             
                            onChange={(e) => handleInputChange(e, setInputRequest)}
                        />
                    </div>                    
                    <button className='sendMessageBtn' onClick={sendMessage}>Send Message</button>
                </div>   
            </div>
            <ModalSuccess isModalVisible={isModalVisible} onModalClose={() => setIsModalVisible(false)} />
        </Media>
        <Media lessThan="md">
            <div id="sendUsMessageMobile">   
                <div className='messageContainer'>
                    <div className='headerMessage'>
                        <Image
                        className='emailIcon'              
                        alt='emailIcon'
                        src={asset('/images/footer/sendUsMessage/desktop/emailIcon.svg')}                  
                        width="40"
                        height="40"
                        />                                          
                        <h2>
                            Send us a message :
                        </h2>
                    </div>
                    <p>Fill out our form and we will contact you within 24 hours.</p>
                </div>   
                <div className='inputContainer'>
                    <div className='inputList'>
                        <input 
                            type='text'
                            placeholder='First Name *'
                            value={inputFirstName}
                            autoComplete="nope"
                            onChange={(e) => handleInputChange(e, setInputFirstName)}
                        />
                        <input
                            type='text'
                            placeholder='Last Name *'
                            value={inputLastName}
                            autoComplete="nope"
                            onChange={(e) => handleInputChange(e, setInputLastName)}
                        />
                    </div>
                    <div className='inputList'>
                    <input
                        type='email'
                        placeholder='Email *'
                        value={inputEmail}
                        autoComplete="nope"
                        onChange={(e) => handleInputChange(e, setInputEmail)}
                    />
                    <input
                        type='tel'
                        placeholder='Phone number *'
                        value={inputPhoneNumber}
                        autoComplete="nope"
                        onChange={(e) => handleInputChange(e, setInputPhoneNumber)}
                    />
                    </div>
                    <div className='inputList'>
                        <input 
                            type='text'
                            placeholder='Company Name *'
                            value={inputCompanyName}
                            autoComplete="nope"
                            onChange={(e) => handleInputChange(e, setInputCompanyName)}
                        />
                    <SearchableDropdown 
                            options={COUNTRY}
                            label="name"
                            id="id"
                            selectedVal={selectedCountry}
                            handleChange={(val) => setSelectedCountry(val)}                    
                    />
                    </div>
                    <div className='textareaList'>
                        <textarea 
                            type='text'
                            placeholder='Requests *'   
                            value={inputRequest}  
                            autoComplete="nope"                       
                            onChange={(e) => handleInputChange(e, setInputRequest)}
                        />
                    </div>
                    <button className='sendMessageBtn' onClick={sendMessage}>Send Message</button>
                </div>   
            </div>
            <ModalSuccess isModalVisible={isModalVisible} onModalClose={() => setIsModalVisible(false)} />
        </Media>
    </MediaContextProvider>
  )
})

export default SendUsMessage