import React from 'react';
import { useTranslation } from "react-i18next";
import { Link, matchPath, useLocation } from "react-router-dom";
import { toRoute } from "../../../i18n";
import './style.scss';

export const NavigationAbout = React.memo(() => {

  const { t } = useTranslation(['about'])
  const { pathname } = useLocation()

  function isMatchPath(path) {
    return !!matchPath(pathname, { path: `/:lang?${path}` })
  }

  return (
    <div className="navigation-about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="navigation-wrapper">
              <Link to={toRoute("/about")}
                className={isMatchPath('/about') ? "active" : ""}>
                {t("about:Our Principle")}
              </Link>
              <Link to={toRoute("/customer")}
                className={isMatchPath('/customer') ? "active" : ""}>
                {t("about:Our Clients")}
              </Link>
              <Link to={toRoute("/contact")}
                className={isMatchPath('/contact') ? "active" : ""}>
                {t("about:Contact Us")}
              </Link>
              {/*<Link to={(i18n.language !== "th" ? "/"+i18n.language : "") + "/join-us"} className={pathName === "/join-us" ? "active" : ""}>*/}
              {/* {t("about:Work with Us")}*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

})
