import { createMedia } from "@artsy/fresnel";

// @see Bootstrap breakpoints.
const AppMedia = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 992,
    xl: 1300,
  },
})

// Generate CSS to be injected into the head
export const mediaStyle = AppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = AppMedia