import React from 'react';
import { Footer } from "./footer";
import { Navigation } from './navigation';
import { ContentWrapper } from './ContentWrapper';

export class ContentLayout extends React.Component {

  render() {
    return (
      <>
        <Navigation />

        <div className="container-inner">
          <ContentWrapper>
            {this.props.children}
          </ContentWrapper>
        </div>

        <Footer />
      </>
    )
  }

}
