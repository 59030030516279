export const NAVIGATION_DATA = [
  {
    path: '/about-us',
    label: 'About Us',
    items: [
      { path: '/about-us', label: 'Company' },
      { path: '/about-us/thailand-craftmanship', label: 'Our Made in Thailand Craftsmanship' },
      { path: '/about-us/quality-management-system', label: 'Quality Management System' },
      { path: '/about-us/sustainability', label: 'Sustainability' },
      { path: '/about-us/corporate-social-responsibility', label: 'Corporate Social Responsibility' },
      { path: '/about-us/become-a-distributor', label: 'Become A Distributor' },
      {
        path: '',
        label: 'Our Services',
        items: [
          { path: '/branding-and-logo-printing', label: 'Branding and Logo Printing' },
          { path: '/about-us', label: 'Direct Packaging Solutions' },
          { path: '/blog/exclusive-design', label: 'Exclusive Design' },
          { path: '/box-mockups', label: 'Luxury Box Mockup' },
          { path: '/packaging', label: 'Luxury Packaging Design' },
          { path: '/packaging', label: 'Luxury Packaging Examples' },
          { path: '/professional-consultation', label: 'Professional Consultation' },
          { path: '/delivery-destination', label: 'Delivery Destination' },
        ],
        style: { borderBottom: '1px solid #D9D9D9' }
      },
    ]
  },
  {
    path: '/packaging',
    label: 'Packaging',
    items: [
      { path: '/packaging/jewelry-boxes', label: 'Jewelry Boxes' },
      { path: '/packaging/jewelry-cases', label: 'Jewelry Case' },
      { path: '/packaging/jewelry-displays', label: 'Jewelry Display' },
      { path: '/packaging/jewelry-pouches', label: 'Jewelry Pouches' },
      { path: '/packaging/shopping-aids', label: 'Shopping Aids' },
      { path: '/packaging/premium-paper-bags', label: 'Premium Paper Bags' },
      { path: '/packaging/watch-packaging', label: 'Watch Packaging' },
      { path: '/packaging/watch-displays', label: 'Watch Display' },
      {
        path: '#/',
        label: 'Customized Projects',
        items: [
          { path: '/packaging/customized-projects', label: 'Customized Projects' },
          { path: '/packaging/customized-projects/amulets', label: 'Amulets' },
          { path: '/packaging/customized-projects/automotive', label: 'Automotive' },
          { path: '/packaging/customized-projects/coin', label: 'Coin' },
          { path: '/packaging/customized-projects/cosmetics', label: 'Cosmetics' },
          { path: '/packaging/customized-projects/fashion', label: 'Fashion' },
          { path: '/packaging/customized-projects/gold', label: 'Gold' },
          { path: '/packaging/customized-projects/jewels', label: 'Jewels' },
          { path: '/packaging/customized-projects/wine-and-spirits', label: 'Wine and Spirits' },
        ],
        style: { borderBottom: '1px solid #D9D9D9' }
      },
      { path: '/packaging/smart-packaging', label: 'Smart Packaging' },
      { path: '/packaging/eco-friendly-packaging', label: 'Eco-Friendly Packaging' },
      { path: '/packaging/e-commerce-assistant', label: 'E-Commerce Assistant' },
    ]
  },
  {
    path: '/our-materials',
    label: 'Materials',
  },
  {
    path: '/about-us/sustainability',
    label: 'Sustainability',
  },
  {
    path: '/blog',
    label: 'Packaging Blog',
  },
  {
    path: '/contact-us',
    label: 'Contact us',
  },
]