import { buildCreateApi, coreModule, reactHooksModule } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true })
)

const silvermanInstance = axios.create({
  baseURL: 'https://gateway-silverman-website-qsobffrqcq-as.a.run.app',
  headers: {
    Authorization: `Bearer U2lsdmVybWFuMjAyMjAyMDk=`
  }
})

const silvermanBaseQuery = () => async (args, { signal, dispatch, getState }, extraOptions) => {
  try {
    const result = await silvermanInstance({
      ...args,
      // Modify axios options here.
    })
    // console.log('Axios: ', result.data.data?.length)
    return { data: result.data }
  } catch (axiosError) {
    const err = axiosError
    return { error: { status: err.response?.status, data: err.response?.data } };
  }
}

export const silvermanApi = createApi({
  tagTypes: ['Blog', 'Customer', 'Faq', 'Register', 'Tutorial', 'Information', 'Company', 'Heartbeat'],
  baseQuery: silvermanBaseQuery(),
  endpoints: (build) => ({
    getCustomerList: build.query({
      query: () => ({ url: `/customer/list` }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Customer', id })), 'Customer']
          : ['Customer'],
    }),

    getFaqCategory: build.query({
      query: () => ({ url: `/faq/category/` }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Faq', id })), 'Faq']
          : ['Faq'],
    }),
    getFaqContent: build.query({
      query: () => ({ url: `/faq/content/` }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Faq', id })), 'Faq']
          : ['Faq'],
    }),

    postRegisterAll: build.mutation({
      query: (body) => ({
        url: `/register/all/`,
        method: "POST",
        data: body,
      }),
    }),

    getTutorialVideo: build.query({
      query: (queryArg) => ({
        url: `/tutorial/video/`,
        params: {
          id: queryArg?.id,
        },
      }),
      transformResponse: (response) => response.data[0],
      providesTags: (result, error, arg) =>
        result
          ? [{ type: 'Tutorial', id: result.id }]
          : ['Tutorial'],
    }),
    getTutorialVideoList: build.query({
      query: (queryArg) => ({
        url: `/tutorial/video/list/`,
        params: {
          index_first: queryArg?.indexFirst,
          index_last: queryArg?.indexLast,
          page: queryArg?.page,
        },
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Tutorial', id })), 'Tutorial']
          : ['Tutorial'],
    }),
    getTutorialCategory: build.query({
      query: () => ({ url: `/tutorial/category/` }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Tutorial', id })), 'Tutorial']
          : ['Tutorial'],
    }),

    getInformationContent: build.query({
      query: (queryArg) => ({
        url: `/information/content/`,
        params: {
          index_first: queryArg?.indexFirst || 0,
          index_last: queryArg?.indexLast || 1000,
          page: queryArg?.page || 1,
          index_information: queryArg?.id
        },
      }),
      transformResponse: (response) => response,
      providesTags: (result, error, arg) =>
        result.data
          ? [...result.data.map(({ id }) => ({ type: 'Information', id })), 'Information']
          : ['Information'],
    }),
    getInformationIndexinformation: build.query({
      query: (queryArg) => ({
        url: `/information/indexinformation/`,
        params: {
          category: queryArg?.category,
          id: queryArg?.id
        }
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Information', id })), 'Information']
          : ['Information']
      ,
    }),
    getInformationCategory: build.query({
      query: (queryArg) => ({
        url: `/information/category/`,
        params: { id: queryArg?.id }
      }
      ),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Information', id })), 'Information']
          : ['Information'],
    }),

    getCompanyImportfile: build.query({
      query: (queryArg) => ({
        url: `/company/importfile`,
        params: {
          index_first: queryArg?.indexFirst,
          index_last: queryArg?.indexLast,
          page: queryArg?.page,
        },
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) => 
        result
          ? [...result.map(({ id }) => ({ type: 'Company', id })), 'Company']
          : ['Company'],
    }),

  }),
})

export const {
  useGetCustomerListQuery,
  useGetFaqCategoryQuery,
  useGetFaqContentQuery,
  usePostRegisterAllMutation,
  useGetTutorialVideoQuery,
  useGetTutorialVideoListQuery,
  useGetTutorialCategoryQuery,
  useGetInformationContentQuery,
  useGetInformationIndexinformationQuery,
  useGetInformationCategoryQuery,
  useGetCompanyImportfileQuery
} = silvermanApi
