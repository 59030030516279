import PropTypes from 'prop-types';
import React from 'react';
import { asset } from '../../helpers/dom.helper';
import Image from '../../components/image';

function FooterCollapse({ children, title, expanded, titleClassName, collapseClassName, expandedClassName, onToggle }) {

  return (
    <>
      <h5 className={`position-relative ${titleClassName}`}>
        <span>{title}</span>
        <a href="#/" className="d-md-none float-right stretched-link" onClick={onToggle}>
          {
            expanded
              ? <Image src={asset('/icon/expand.svg')} alt={title + '-Expanded'} width="12" height="6" />
              : <Image src={asset('/icon/collapse.svg')} alt={title + '-Collapsed'} width="12" height="6" />
          }
        </a>
      </h5>

      <div className={`${collapseClassName} ${expanded ? expandedClassName : ''}`}>
        {children}
      </div>
    </>
  )

}

FooterCollapse.defaultProps = {
  expanded: false,
  titleClassName: 'headline',
  collapseClassName: 'd-md-block collapse',
  expandedClassName: 'show',
  onToggle: e => { }
}

FooterCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  titleClassName: PropTypes.string,
  collapseClassName: PropTypes.string,
  expandedClassName: PropTypes.string,
  onToggle: PropTypes.func
}

export default FooterCollapse