import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import FsBackend from "i18next-fs-backend";
import HttpBackend from "i18next-http-backend";
import path from 'path';
import { initReactI18next } from "react-i18next";

const isBrower = (typeof window !== 'undefined')
const options = {
  lngs: ['th', 'en'],
  load: 'languageOnly',
  fallbackLng: 'th',
  preload: (isBrower ? false : ['th', 'en']),
  keySeparator: '->',
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: false,
    wait: isBrower
  },
  backend: {
    backends: isBrower ? [HttpBackend] : [
      HttpBackend,
      FsBackend
    ],
    backendOptions: isBrower ? [
      { loadPath: '/locales/{{lng}}/{{ns}}.json' }
    ] : [
      { loadPath: '/locales/{{lng}}/{{ns}}.json' },
      { loadPath: path.resolve(__dirname, '../public/locales/{{lng}}/{{ns}}.json') }
    ]
  }
}

i18n.use(ChainedBackend)

if (isBrower) {
  i18n.use(initReactI18next)
}

if (!i18n.isInitialized) {
  i18n.init(options)
}

/**
* @param {String} field
* @return {String}
*/
export function tField(field) {
  const localeSuffix = `_${i18n.language}`
  return field.endsWith(localeSuffix) ? field : `${field}${localeSuffix}`
}

/**
 * @param {Object} model
 * @param {String} field
 * @param {*} noValue
 * @param {Number} branch
 * @return {*}
 */
export function tValue(model, field, noValue, branch = 0) {
  const fieldParts = field.split('.')
  if (branch === (fieldParts.length - 1)) {
    const fieldTarget = tField(fieldParts[branch])
    return model.hasOwnProperty(fieldTarget)
      ? model[fieldTarget]
      : (
        // Use default field-name without suffix.
        model.hasOwnProperty(field)
          ? model[field]
          : noValue
      )
  }

  const fieldNext = fieldParts[branch]
  return model.hasOwnProperty(fieldNext)
    ? tValue(model[fieldNext], field, noValue, branch + 1)
    : noValue
}

export function toRoute(path) {
  if (/^https?:\/\//.test(path)) return path
  return `/${i18n.language || 'th'}${path}`.replace(/^\/th\//, '/')
}

export default i18n
