import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Image from "../../components/image";
import { asset } from '../../helpers/dom.helper';
import { toRoute } from "../../i18n";
import { NAVIGATION_DATA } from "./_data";

const Submenu = React.memo(({ parent, items }) => {	
	const { pathname } = useLocation()
	const [expanded, setExpanded] = useState(false)

	const handleLinkClick = (event) => {
		event.preventDefault(); // Prevent default behavior of the link
		setExpanded(!expanded);
	}

	const handleItemClick = (event) => {
		// Prevent the click event from propagating to the parent container
		event.stopPropagation();
	}

	return (
		<div className="w-100" onClick={handleItemClick}>
			<Link to={pathname} className="d-flex" onClick={handleLinkClick}>
				<span className="mr-auto">{parent.label}</span>
				<Image
					src={asset(expanded ? '/icon/collapse.svg' : '/icon/arrow-forward.svg')}
					alt={parent.label} width="12" height="6"
					className="w-auto h-auto"
				/>
			</Link>
			<ul className={`list-unstyled pl-3 ${expanded ? 'd-block' : 'd-none'}`}>
				{items?.map((it, idx) => (
					<li key={idx}>
						<Link to={toRoute(it.path)}>{it.label}</Link>
					</li>
				))}
			</ul>
		</div>
	);
});


const Menu = React.memo(() => {

	return (
		<div className="menu">
			{NAVIGATION_DATA?.map((nav, key) => (
				<div className="d-block" key={key}>
					{nav.items ? <h6>{nav.label}</h6> : <></>}

					{nav.items ?
					nav.items?.map((it, idx) => (
						it.items?.length
							? <Submenu parent={it} items={it.items} key={`${key}${idx}`} />
							: <Link to={toRoute(it.path)} key={`${key}${idx}`}>{it.label}</Link>
					))
						:
					<h6><Link to={toRoute(nav.path)} key={`${key}`}>{nav.label}</Link></h6>
					}

					<hr />
				</div>
			))}
		</div>
	)
})

export const MobileNavigation = React.memo(() => {

	const location = useLocation()
	const [show, setShow] = useState(false)

	useEffect(() => {
		setShow((currentValue) => currentValue ? false : currentValue)
	}, [location])

	return (
		<>
			<Image src={asset('/icon/ihamburger-menu.svg')} alt="menu" className="menu-toggle mr-2 ml-2" width="30" height="17" onClick={() => setShow(true)} />
				<div className={`mobile-navigation ${show ? 'show' : ''}`}>
						<Link to={toRoute('')}>
							<Image src={asset('/images/navigation/LussopackLogo.webp')} alt="lussopack" width="145" height="42" className="logo" />
						</Link>

						<Image src={asset('/icon/close.svg')} alt="lussopack" width="21" height="21" className="navigation-close" onClick={() => setShow(false)} />
						<hr />

						<Menu />
				</div>
				<div className={`backdrop ${show ? 'show' : ''}`} onClick={() => setShow(false)}></div>
		</>
	)

})
