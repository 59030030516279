import React from 'react';

export function ContentWrapper({ children, className, ...restProps }) {

  return (
    <div {...restProps} className={`container ${className || ''}`}>
      {children}
    </div>
  )

}
