import React from 'react';
import { Route } from 'react-router-dom';
import { DefaultLayout } from './DefaultLayout';

export function DefaultRoute({ component: Component, ...rest }) {

  return (
    <Route
      {...rest}
      render={routeProps => (
        <DefaultLayout>
          <Component {...routeProps} />
        </DefaultLayout>
      )}
    />
  )

}