import { configureStore } from '@reduxjs/toolkit';
import { silvermanApi } from './services';

export function registerStore(preloadedState) {
  return configureStore({
    reducer: {
      [silvermanApi.reducerPath]: silvermanApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(silvermanApi.middleware),
    preloadedState
  })
}