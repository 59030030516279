import loadable from "@loadable/component";
import React, {useEffect, useRef} from 'react';
import { Route, Switch, useLocation} from "react-router-dom";
import './App.scss';
import { ContentRoute, DefaultRoute } from './layouts';

const AboutUs = loadable(() => import("./pages/aboutUs/aboutUs/aboutUs"))
const ThailandCraftmanship = loadable(() => import("./pages/aboutUs/thailandCraftman/thailandCraftman"))
const QualityManagementSystem = loadable(()=>import("./pages/aboutUs/qualityManagementSystem/qualityManagementSystem"))
const Sustainabillity = loadable(()=>import("./pages/aboutUs/sustainability/sustainability"))
const CorporateSocialResponsibility = loadable(()=>import("./pages/aboutUs/corporateSocialResponsibility/corporateSocialResponsibility"))
const BecomeADistributor = loadable(()=>import("./pages/aboutUs/becomeADistributor/becomeADistributor"))
const OurMaterial = loadable(()=>import("./pages/ourMaterial/ourMaterial"))
const PackagingBlog = loadable(()=>import("./pages/packagingBlog/packagingBlog"))
const ContactUs = loadable(()=>import("./pages/contactUs/contactUs"))
const DeliveryDestination = loadable(()=>import("./pages/deliveryDestination/deliveryDestination"))
const BrandingandLogoPrinting = loadable(()=>import("./pages/brandingandLogoPrinting/brandingandLogoPrintings.js"))
const ProfessionalConsultation = loadable(()=>import("./pages/professionalConsultation/professional Consultation.js"))
const BoxMockUps = loadable(()=>import("./pages/boxMockups/boxMockups.js"))
const PerfumePackaging = loadable(()=>import("./pages/perfumePackaging/perfumePackaging.js"))



// packaging
const Packaging = loadable(()=>import("./pages/packaging/packaging/packaging"))
const JewelryBoxes = loadable(()=>import("./pages/packaging/jewelryBoxes/jewelryBoxes"))
const JewelryCases = loadable(()=>import("./pages/packaging/jewelryCases/jewelryCases"))
const JewelryDisplays = loadable(()=>import("./pages/packaging/jewelryDisplay/jewelryDisplays"))
const JewelryPouches = loadable(()=>import("./pages/packaging/jewelryPouches/jewelryPouches"))
const ShoppingAids = loadable(()=>import("./pages/packaging/shoppiingAid/shoppingAids"))
const ShoppingAidsTissueBox = loadable(()=>import("./pages/packaging/shoppiingAid/shoppingAidsTissueBox/shoppingAidItem"))
const ShoppingAidsJewelryTray = loadable(()=>import("./pages/packaging/shoppiingAid/shoppingAidsJewelryTray/shoppingAidItem"))
const ShoppingAidsMirror = loadable(()=>import("./pages/packaging/shoppiingAid/shoppingAidsMirror/shoppingAidItem"))
const PremiumPaperBags = loadable(()=>import("./pages/packaging/premiumPaperBags/premiumPaperBags"))
const WatchPackaging = loadable(()=>import("./pages/packaging/watchPackaging/watchPackaging"))
const WatchDisplays = loadable(()=>import("./pages/packaging/watchDisplay/watchDisplays"))
const SmartPackaging = loadable(()=>import("./pages/packaging/smartPackaging/smartPackaging"))
const EcoFriendlyPackaging = loadable(()=>import("./pages/packaging/ecoFriendlyPackaging/ecoFriendlyPackaging"))
const ECommerceAssistant = loadable(()=>import("./pages/packaging/eCommerceAssistant/eCommerceAssistant"))

// footer
const WorkWithUs = loadable(() => import("./pages/workWithUs/workWithUs"))
const Privacy = loadable(() => import("./pages/privacy"))
const TermOfUser = loadable(() => import("./pages/termOfUser"))
const Sitemap = loadable(() => import("./pages/sitemap/Sitemap"))

// customizedProjects
const CustomizedProjects = loadable(()=>import("./pages/customizedProjects/customizedProjects/customizedProjects"))
const Amulets = loadable(()=>import("./pages/customizedProjects/amulets/amulet"))
const Automotive = loadable(()=>import("./pages/customizedProjects/automotive/automotive"))
const Coin = loadable(()=> import("./pages/customizedProjects/coin/coin"))
const Cosmetics = loadable(()=> import("./pages/customizedProjects/cosmetics/cosmetics"))
const Fashion = loadable(()=> import("./pages/customizedProjects/fashion/fashion"))
const Gold = loadable(()=> import("./pages/customizedProjects/gold/gold"))
const Jewel = loadable(()=> import("./pages/customizedProjects/jewel/jewel"))
const WineAndSpirits = loadable(()=> import("./pages/customizedProjects/wineAndSpirits/wineAndSpirits"))


const JewelryBoxesPage = loadable(() => import("./pages/itemAndBlog/jewel/jewelryBoxes/jewelPage"))

const JewelryCasesPage = loadable(() => import("./pages/itemAndBlog/jewel/jewelryCases/jewelPage"))
const JewelryDisplaysPage = loadable(() => import("./pages/itemAndBlog/jewel/jewelryDisplay/jewelPage"))
const JewelryPouchPage = loadable(() => import("./pages/itemAndBlog/jewel/jewelryPouch/jewelPage"))
const PremiumPaperBagsPage = loadable(() => import("./pages/itemAndBlog/jewel/premiumPaperBags/jewelPage"))
const ShoppingAidsPage = loadable(() => import("./pages/itemAndBlog/jewel/shoppingAid/jewelPage"))
const WatchDisplaysPage = loadable(() => import("./pages/itemAndBlog/jewel/watchDisplay/jewelPage"))
const WatchPackagingPage = loadable(() => import("./pages/itemAndBlog/jewel/watchPackaing/jewelPage"))
const EcoFriendlyPage = loadable(() => import("./pages/itemAndBlog/jewel/ecoFriendlyPackaging/jewelPage"))
const ECommerceAssistantPage = loadable(() => import("./pages/itemAndBlog/jewel/ecommerceAssistant/jewelPage"))

const AmuletPage = loadable(() => import("./pages/itemAndBlog/customized/amulet/jewelPage"))
const AutomotivePage = loadable(() => import("./pages/itemAndBlog/customized/automotive/jewelPage"))
const CoinPage = loadable(() => import("./pages/itemAndBlog/customized/coin/jewelPage"))
const CosmeticPage = loadable(() => import("./pages/itemAndBlog/customized/cosmetic/jewelPage"))
const FashionPage = loadable(() => import("./pages/itemAndBlog/customized/fashion/jewelPage"))
const GoldPage = loadable(() => import("./pages/itemAndBlog/customized/gold/jewelPage"))
const WineAndSpiritPage = loadable(() => import("./pages/itemAndBlog/customized/wineAndSpirit/jewelPage"))

const BlogPageTemplate = loadable(() => import("./pages/packagingBlog/blogDetail/blogPagetemplate/blogPage"))

const Home = loadable(() => import("./pages/Home/Home"))

const NoMatch = loadable(() => import("./pages/error/404"))

function App() {  
  const prevLocation = useRef();

  const location = useLocation();
  useEffect(() => {
    if (prevLocation.current !== location.pathname) {      
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    prevLocation.current = location.pathname;    
  }, [location]);

  return (
    <Switch>
      {/* About-Us */}
      <DefaultRoute  exact path="/about-us" component={AboutUs} />
      <DefaultRoute  exact path="/about-us/thailand-craftmanship" component={ThailandCraftmanship} />
      <DefaultRoute exact path="/about-us/quality-management-system" component={QualityManagementSystem} />
      <DefaultRoute exact path="/about-us/sustainability" component={Sustainabillity} />
      <DefaultRoute exact path="/about-us/corporate-social-responsibility" component={CorporateSocialResponsibility} />
      <DefaultRoute exact path="/about-us/become-a-distributor" component={BecomeADistributor} />
      
      <DefaultRoute exact path="/blog" component={PackagingBlog} />
      <DefaultRoute exact path="/blog/:blogitem?" component={BlogPageTemplate} />  

      <DefaultRoute exact path="/our-materials" component={OurMaterial} />        

      <DefaultRoute exact path="/contact-us" component={ContactUs} />
      <DefaultRoute exact path="/delivery-destination" component={DeliveryDestination} />
      <DefaultRoute exact path="/branding-and-logo-printing" component={BrandingandLogoPrinting} />
      <DefaultRoute exact path="/professional-consultation" component={ProfessionalConsultation} />
      <DefaultRoute exact path="/box-mockups" component={BoxMockUps} />
      <DefaultRoute exact path="/perfume-packaging" component={PerfumePackaging} />

      
      {/* //packaging  */}
      <DefaultRoute exact path="/packaging" component={Packaging} />
      <DefaultRoute exact path="/packaging/jewelry-boxes" component={JewelryBoxes} />
      <DefaultRoute exact path="/packaging/jewelry-cases" component={JewelryCases} />
      <DefaultRoute exact path="/packaging/jewelry-displays" component={JewelryDisplays} />
      <DefaultRoute exact path="/packaging/jewelry-pouches" component={JewelryPouches} />
      <DefaultRoute exact path="/packaging/shopping-aids" component={ShoppingAids} />
      <DefaultRoute exact path="/packaging/shopping-aids/tissueBoxes" component={ShoppingAidsTissueBox} />
      <DefaultRoute exact path="/packaging/shopping-aids/jewelryTrays" component={ShoppingAidsJewelryTray} /> 
      <DefaultRoute exact path="/packaging/shopping-aids/mirror" component={ShoppingAidsMirror} />

      <DefaultRoute exact path="/packaging/premium-paper-bags" component={PremiumPaperBags} />
      <DefaultRoute exact path="/packaging/watch-packaging" component={WatchPackaging} />
      <DefaultRoute exact path="/packaging/watch-displays" component={WatchDisplays} />
      <DefaultRoute exact path="/packaging/smart-packaging" component={SmartPackaging} />
      <DefaultRoute exact path="/packaging/eco-friendly-packaging" component={EcoFriendlyPackaging} />
      <DefaultRoute exact path="/packaging/e-commerce-assistant" component={ECommerceAssistant} /> 

      {/* jewelry-boxes list */}
      <DefaultRoute exact path="/packaging/jewelry-boxes/:jewels?" component={JewelryBoxesPage} />
      <DefaultRoute exact path="/packaging/jewelry-cases/:jewels?" component={JewelryCasesPage} />
      <DefaultRoute exact path="/packaging/jewelry-displays/:jewels?" component={JewelryDisplaysPage} />
      <DefaultRoute exact path="/packaging/jewelry-pouches/:jewels?" component={JewelryPouchPage} />
      <DefaultRoute exact path="/packaging/premium-paper-bags/:jewels?" component={PremiumPaperBagsPage} />
      <DefaultRoute exact path="/packaging/shopping-aids/:jewels?" component={ShoppingAidsPage} />
      <DefaultRoute exact path="/packaging/shopping-aids/tissueBoxes/:jewels?" component={ShoppingAidsPage} />
      <DefaultRoute exact path="/packaging/shopping-aids/jewelryTrays/:jewels?" component={ShoppingAidsPage} />
      <DefaultRoute exact path="/packaging/shopping-aids/mirror/:jewels?" component={ShoppingAidsPage} />


      <DefaultRoute exact path="/packaging/watch-displays/:jewels?" component={WatchDisplaysPage} />
      <DefaultRoute exact path="/packaging/watch-packaging/:jewels?" component={WatchPackagingPage} />
      <DefaultRoute exact path="/packaging/eco-friendly-packaging/:jewels?" component={EcoFriendlyPage} />
      <DefaultRoute exact path="/packaging/e-commerce-assistant/:jewels?" component={ECommerceAssistantPage} />

      {/* // customized-projects */}
      <DefaultRoute exact path="/packaging/customized-projects" component={CustomizedProjects} />
      <DefaultRoute exact path="/packaging/customized-projects/amulets" component={Amulets} />
      <DefaultRoute exact path="/packaging/customized-projects/automotive" component={Automotive} />
      <DefaultRoute exact path="/packaging/customized-projects/coin" component={Coin} />
      <DefaultRoute exact path="/packaging/customized-projects/cosmetics" component={Cosmetics} />
      <DefaultRoute exact path="/packaging/customized-projects/fashion" component={Fashion} />
      <DefaultRoute exact path="/packaging/customized-projects/gold" component={Gold} />
      <DefaultRoute exact path="/packaging/customized-projects/jewels" component={Jewel} />
      <DefaultRoute exact path="/packaging/customized-projects/wine-and-spirits" component={WineAndSpirits} />



      <DefaultRoute exact path="/packaging/customized-projects/amulets/:jewels?" component={AmuletPage} />
      <DefaultRoute exact path="/packaging/customized-projects/automotive/:jewels?" component={AutomotivePage} />
      <DefaultRoute exact path="/packaging/customized-projects/coin/:jewels?" component={CoinPage} />
      <DefaultRoute exact path="/packaging/customized-projects/cosmetics/:jewels?" component={CosmeticPage} />
      <DefaultRoute exact path="/packaging/customized-projects/fashion/:jewels?" component={FashionPage} />
      <DefaultRoute exact path="/packaging/customized-projects/gold/:jewels?" component={GoldPage} />
      {/* <DefaultRoute exact path="/packaging/customized-projects/coin/:jewels?" component={jewelPage} /> */}
      <DefaultRoute exact path="/packaging/customized-projects/wine-and-spirits/:jewels?" component={WineAndSpiritPage} />            

      {/* footer Content */}
      <DefaultRoute path="/term-of-user" component={TermOfUser} /> 
      <DefaultRoute path="/privacy" component={Privacy} />
      <ContentRoute path="/sitemap" component={Sitemap} />
      
      <DefaultRoute path="/work-with-us" component={WorkWithUs} />

      <DefaultRoute exact path="/" component={Home} />
      <Route path={"*"} component={NoMatch} />
    </Switch>
  )

}

export default App
