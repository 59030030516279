export const FOOTER_LINKS = [
  {
    label: 'Lussopack Profile',
    url: '#/',
    items: [
      { label: 'About Us', url: '/about-us' },
      { label: 'Best Luxury Packaging', url: '/packaging' },
      { label: 'Contact Us', url: '/contact-us' },
      { label: 'Work with Us', url: '/work-with-us' },
      { label: 'Our Made in Thailand Craftsmanship', url: '/about-us/thailand-craftmanship' },
      { label: 'Quality Management System', url: '/about-us/quality-management-system' },
      { label: 'Sustainability', url: '/about-us/sustainability' },
      { label: 'Corporate Social Responsibility', url: '/about-us/corporate-social-responsibility' },
      { label: 'Become A Distributor', url: '/about-us/become-a-distributor' },
    ]
  },
  {
    label: 'Our products',
    url: '#/',
    items: [
      { label: 'Jewelry Boxes', url: '/packaging/jewelry-boxes' },
      { label: 'Jewelry Case', url: '/packaging/jewelry-cases' },
      { label: 'Jewelry Displays', url: '/packaging/jewelry-displays' },
      { label: 'Jewelry Pouches', url: '/packaging/jewelry-pouches' },
      { label: 'Shopping Aids', url: '/packaging/shopping-aids' },
      { label: 'Premium Paper Bags', url: '/packaging/premium-paper-bags' },
      { label: 'Watch Packaging', url: '/packaging/watch-packaging' },
      { label: 'Watch Displays', url: '/packaging/watch-displays' },
      { label: 'Smart Packaging', url: '/packaging/smart-packaging' },
      { label: 'Eco-Friendly Packaging', url: '/packaging/eco-friendly-packaging' },
      { label: 'E-Commerce Assistant', url: '/packaging/e-commerce-assistant' },
    ]
  },
  {
    label: 'Our Services',
    url: '#/',
    items: [
      { label: 'Box Mockup', url: '/box-mockups' },
      { label: 'Branding and Logo Printing', url: '/branding-and-logo-printing' },
      { label: 'Direct Packaging Solutions', url: '/about-us' },
      { label: 'Exclusive Design', url: '/blog/exclusive-design' },
      { label: 'Gift Box Mockup', url: '/box-mockups' },
      { label: 'Luxury Box Mockup', url: '/box-mockups' },
      { label: 'Luxury Packaging Design', url: '/packaging' },
      { label: 'Luxury Packaging Examples', url: '/packaging' },
      { label: 'Professional Consultation', url: '/professional-consultation' },
      { label: 'Watch Box Mockup', url: '/box-mockups' },
    ]
  },
  {
    label: 'Our customized packaging',
    url: '#/',
    items: [
      { label: 'Amulets', url: '/packaging/customized-projects/amulets' },
      { label: 'Automotive', url: '/packaging/customized-projects/automotive' },
      { label: 'Coin', url: '/packaging/customized-projects/coin' },
      { label: 'Cosmetics', url: '/packaging/customized-projects/cosmetics' },
      { label: 'Fashion', url: '/packaging/customized-projects/fashion' },
      { label: 'Gold', url: '/packaging/customized-projects/gold' },
      { label: 'Jewels', url: '/packaging/customized-projects/jewels' },
      { label: 'Wine and Spirits', url: '/packaging/customized-projects/wine-and-spirits' },
    ]
  },
  {
    label: 'Luxury Packaging Ideas',
    url: '#/',
    items: [
      { label: 'Elevating luxury packaging to new standard', url: '/blog/luxury-packaging-market'},
      { label: 'From Concept to Creation: The process of designing a luxury package', url: '/blog/eco-friendly-packaging-for-luxury-brands' },
      { label: 'How can I make my packaging unique?', url: '/blog/brand-packaging-importance-choosing-tips' },
      { label: 'How can luxury brands adopt sustainable packaging effectively?', url: '/blog/how-can-luxury-brands-adopt-sustainable-packaging-effectively' },
      { label: 'How do I make my packaging look luxurious?', url: '/blog/eco-friendly-packaging-for-luxury-brands' },
      { label: 'Luxury packaging market', url: '/blog/luxury-packaging-market' },
      { label: 'The impact of luxury packaging on brand perception', url: '/blog/luxury-packaging-guide' },
      { label: 'The role of luxury packaging in branding and marketing', url: '/blog/luxury-packaging-guide' },
      { label: 'What are the elements of luxury packaging?', url: '/blog/eco-friendly-packaging-for-luxury-brands' },
      { label: 'What makes good luxury packaging?', url: '/blog/eco-friendly-packaging-for-luxury-brands' },
    ]
  },
  {
    label: 'Packaging Selection Guide',
    url: '#/',
    items: [
      { label: 'Custom vs. Standard Packaging', url: '/blog/custom-vs.standard-packaging' },
      { label: 'Everything you need to know about luxury packaging', url: '/blog/everything-you-need-to-know-about-luxury-packaging' },
      { label: 'Functions of all luxury packaging', url: '/blog/functions-of-all-luxury-packaging' },
      { label: 'How do you package luxury products?', url: '/blog/brand-packaging-importance-choosing-tips' },
      { label: 'How to choose the right packaging for your product', url: '/blog/brand-packaging-importance-choosing-tips' },
      { label: 'The most common packaging mistakes businesses make', url: '/blog/brand-packaging-importance-choosing-tips' },
      { label: 'The right packaging materials for your product', url: '/blog/brand-packaging-importance-choosing-tips' },
      { label: 'The ultimate guide to packaging materials', url: '/blog/brand-packaging-importance-choosing-tips' },
      { label: 'Tips to Optimize Your E-Commerce Packaging', url: '/blog/tip-to-optimize-your-e-commerce-packaging' },
      { label: 'What do you consider when choosing packaging material', url: '/blog/brand-packaging-importance-choosing-tips' },
    ]
  },
  {
    label: 'Forest Stewardship Council (FSC)',
    url: '#/',
    items: [
      { label: 'Benefits of Using FSC-Certified Packaging', url: "/blog/fsc-certified-guidelines" },
      { label: "How FSC Certification enhances your company's image", url: "/blog/fsc-certified-guidelines" },
      { label: 'How FSC labels empower shoppers to make sustainable choices', url: "/blog/fsc-certified-guidelines" },
      { label: 'Sustainability and Luxury Packaging', url: '/blog/how-can-luxury-brands-adopt-sustainable-packaging-effectively' },
      { label: 'Sustainable Packaging Inspiration', url: '/blog/how-can-luxury-brands-adopt-sustainable-packaging-effectively' },
      { label: 'The Importance of FSC-Certified Packaging', url: '/blog/fsc-certified-guidelines' },
      { label: 'Types of FSC certification', url: '/blog/fsc-certified-guidelines' },
      { label: 'Understanding FSC Certification', url: '/blog/fsc-certified-guidelines' },
      { label: 'What is the purpose of FSC Label?', url: '/blog/what-is-the-purpose-of-FSC-label' },
      { label: 'What’s the Forest Stewardship Council (FSC)?', url: '/blog/whats-the-forest-stewardship-council(FSC)' },
    ]
  },
  {
    label: 'Eco-Friendly Packaging',
    url: '#/',
    items: [
      { label: 'Benefits of Eco-Friendly Packaging', url: '/blog/eco-friendly-packaging-guidelines' },
      { label: 'Eco-friendly Luxury Packaging to Inspire You', url: '/blog/eco-friendly-packaging-for-luxury-brands' },
      { label: 'Eco-friendly packaging ideas', url: '/blog/eco-friendly-packaging-for-luxury-brands' },
      { label: 'Eco-Friendly Packaging Solutions', url: '/blog/eco-friendly-packaging-guidelines' },
      { label: 'How does eco-friendly packaging contribute to sustainability?', url: '/blog/eco-friendly-packaging-guidelines' },
      { label: 'Specific certifications for eco-friendly packaging materials', url: '/blog/fsc-certified-guidelines' },
      { label: 'The rise of eco-friendly packaging solutions', url: '/blog/eco-friendly-packaging-guidelines' },
      { label: 'What are some common materials used in eco-friendly packaging?', url: '/blog/what-are-some-common-materials-used-in-eco-friendly-packaging' },
      { label: 'What is eco-friendly packaging?', url: '/blog/eco-friendly-packaging-guidelines' },
      { label: 'Why is eco-friendly packaging important?', url: '/blog/eco-friendly-packaging-guidelines' },
    ]
  },
]