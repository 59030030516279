import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../../components/image';
import { asset } from '../../helpers/dom.helper';
import { toRoute } from "../../i18n";
import styles from './footer.module.scss';
import FooterCollapse from './FooterCollapse';
import { FOOTER_LINKS } from './_data';
import SendUsMessage from './sendUsMessages';
import { Media, MediaContextProvider } from '../../media';

export class Footer extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      expandedContents: {
        download: false,
        service: false,
        contact: false
      },
      defaultLinksCount: 3,
      expandedLinks: FOOTER_LINKS.map(() => false)
    }

    this.onExpandedContentToogle = this.onExpandedContentToogle.bind(this)
    this.onExpandedLinkToogle = this.onExpandedLinkToogle.bind(this)
  }

  componentWillMount() {
    const script = document.createElement('script');
    script.src = 'https://www.trustmarkthai.com/callbackData/initialize.js?t=a1-26-6-1e5236f354ef0ff8ec9b24c31188233ce5daf1001db8';
    script.id = 'dbd-init'; 
    document.body.appendChild(script);
  }

  componentWillUnmount() {
      const script = document.getElementById('dbd-init');
      if (script) {
          document.body.removeChild(script);
      }
  }


  onExpandedContentToogle(event, entry) {
    event.preventDefault()

    let { expandedContents } = this.state
    expandedContents[entry] = !expandedContents[entry]
    this.setState({ expandedContents: { ...expandedContents } })
  }

  onExpandedLinkToogle(event, index) {
    event.preventDefault()

    let { expandedLinks } = this.state
    expandedLinks[index] = !expandedLinks[index]
    this.setState({ expandedLinks: [...expandedLinks] })
  }

  
  render() {
    const { defaultLinksCount, expandedLinks } = this.state
    return (       
          <div>
            <SendUsMessage />
            <MediaContextProvider>
              <Media greaterThanOrEqual="md">
                <div className={styles.footer}>              
                  <div className={styles.footerContactIcon}>
                    <div className="row">
                      <div className="col-12 col-md mb-4 footer-download">
                        <img 
                          alt="logoLussoPack"
                          src={asset('/images/navigation/LussopackLogo.svg')}
                        />
                        <hr className="mt-4 mb-0 d-sm-none" style={{ margin: '0 -1rem' }} />
                      </div>

                      <div className="mb-4 footer-social">
                        <h5 className="headline d-inline-block d-md-block mr-3">Contact us for more information</h5>
                        <a href="https://page.line.me/290lqtxf?openQrModal=true" target="_blank"
                          rel="noopener noreferrer">
                          <Image src={asset('/images/footer/social/desktop/lineIcon.svg')} alt="line" width="40" height="40" className="footer-social-icon" />
                        </a>
                        <a href="https://api.whatsapp.com/message/5JQHETSSNKG5B1?autoload=1&app_absent=0" target="_blank"
                        rel="noopener noreferrer">
                          <Image src={asset('/images/footer/social/desktop/whatAppIcon.svg')} alt="whatApp" width="40" height="40" className="footer-social-icon" />
                        </a>
                        <a href="https://www.facebook.com/LussoPack/" target="_blank"
                          rel="noopener noreferrer">
                          <Image src={asset('/images/footer/social/desktop/facebookIcon.svg')} alt="facebook" width="40" height="40" className="footer-social-icon" />
                        </a>
                        <a href="https://www.instagram.com/lussopack/" target="_blank"
                          rel="noopener noreferrer">
                          <Image src={asset('/images/footer/social/desktop/igIcon.svg')} alt="ig" width="40" height="40" className="footer-social-icon" />
                        </a>
                        <a href="https://th.linkedin.com/company/lussopack" target="_blank"
                          rel="noopener noreferrer">
                          <Image src={asset('/images/footer/social/desktop/linkinIcon.svg')} alt="linkIn" width="40" height="40" className="footer-social-icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-0 mb-3" />

                  <div className={styles.footerContact}>              
                      <div className={styles.contactAbout}>
                        <p>Lussopack is your premier destination for cutting-edge <b>luxury packaging solutions</b>, offering a wide range of <b>world class materials</b> and a comprehensive <b>Quality Management System (QMS).</b> We specialize in crafting <b>high-quality, customizable packaging</b> designed to protect and enhance your precious products. With <b>sustainability as standard,</b> we offer eco-friendly packaging as options that would align with your green initiatives. Our professional team ensures precise, efficient packaging tailored to your needs. Discover the Lussopack difference - <b>where innovation meets sustainability,</b> and packaging becomes an extension of your brand.</p>
                      </div>
                      
                      <div className={styles.contactMemberOf}>
                        <h5>Member of</h5>
                        <div className='d-flex flex-row'> 
                          <div id="Certificate-banners" className={`${styles.dbdBanner} mr-2`} width="108" height="108"></div>                     
                          <a href={"https://www.bkkgems.com/"} rel="noopener noreferrer" target="_blank">
                            <img 
                              alt="memberOf1"
                              src={asset('/images/footer/contact/desktop/memberOf1.svg')}
                              width="67"
                              height="66"
                            />           
                          </a>         
                          <a href={"https://www.ieat.go.th/th/"} rel="noopener noreferrer" target="_blank">
                            <img 
                              alt="memberOf2"
                              src={asset('/images/footer/contact/desktop/memberOf2.svg')}
                              width="67"
                              height="66"
                            />      
                           </a>            
                          <div className='d-flex flex-column'>     
                            <a href={"https://www.ditp.go.th/"} rel="noopener noreferrer" target="_blank">   
                              <img 
                                alt="memberOf3"
                                src={asset('/images/footer/contact/desktop/memberOf3.svg')}
                                width="67"
                                height="33"
                              />       
                            </a>           
                            <a href={"https://git.or.th/th/home#gallery-popup-1"} rel="noopener noreferrer" target="_blank">  
                              <img 
                                alt="memberOf4"
                                src={asset('/images/footer/contact/desktop/memberOf4.svg')}
                                width="67"
                                height="33"
                              />             
                            </a>  
                          </div>       
                        </div>                        
                      </div>

                      <div className={styles.contactUs}>
                          <h5>Contact Us</h5>
                          <div className="row no-gutters">
                            <div className="col-12">
                              <div className={styles.contactUsList}>
                                <img 
                                  alt="contactTel"
                                  src={asset('/images/footer/contact/desktop/contactTel.svg')}
                                  width="20"
                                  height="20"
                                />
                                {/* <p>(+66)2 233 0744<br/>(+66)2 233 0745</p> */}
                                <p>(+66)95-049-4747</p>
                              </div>
                              <div className={styles.contactUsList}>
                                <img 
                                  alt="contactTel"
                                  src={asset('/images/footer/contact/desktop/contactMail.svg')}
                                  width="20"
                                  height="20"
                                />
                                <p>
                                  <a href="mailto:hello@lussopack.com">hello@lussopack.com</a>
                                </p>
                              </div>
                              <div className={styles.contactUsList}>
                                <img 
                                  alt="contactLocate"
                                  src={asset('/images/footer/contact/desktop/contactLocate.svg')}
                                  width="20"
                                  height="20"
                                />
                                <p>1086/9-10 2FL., Charoenkrung Rd., Bangrak,Bangrak,Bangkok 10500 Thailand</p>
                              </div>
                            </div>
                          </div>
                      </div>              
                  </div>
                  <hr className="mt-0 mb-3" />

                  <div className={styles.footerMoreInfo}>   
                  <div className="container p-0">
                    <div className="row footer-links">
                      {FOOTER_LINKS.map((menu, menuIndex) => {
                        return (
                          <div className="col-12 col-md-3 mb-4 footer-links-item" key={menuIndex}>
                            <FooterCollapse title={menu.label} expanded={expandedLinks[menuIndex]} onToggle={e => this.onExpandedLinkToogle(e, menuIndex)}>
                              {(expandedLinks[menuIndex] ? menu.items : menu.items.slice(0, defaultLinksCount)).map((it, i) => (
                                <Link to={toRoute(it.url)} className="d-block" key={i}>{it.label}</Link>
                              ))}
                            </FooterCollapse>

                            <b><a href="#/" className="d-none d-md-block mt-2" onClick={e => this.onExpandedLinkToogle(e, menuIndex)}>{expandedLinks[menuIndex] ? 'Read Less' : 'Read More'}</a></b>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  </div>

                  <div className="footer-nav">
                    <div className="container py-3 py-md-0 footer-nav-container" style={{width: '80%'}}>
                      <div className="row align-items-md-center text-left">
                        <div className="col py-2">
                          <Link to={toRoute('/term-of-user')}>Terms and Conditions</Link>
                        </div>
                        <div className="col py-2">
                          <Link to={toRoute('/privacy')}>Privacy Policy</Link>
                        </div>
                        <div className="col-12 col-md">
                          <Link to={toRoute('/sitemap')}>Lussopack Sitemap</Link>
                        </div>
                        <div className="col-12 col-md" style={{width: '25%', fontSize: '10px'}}>
                          <Link to={'/!#'}>Copyright 2024 Asia Packaging Group Co.,Ltd.</Link>
                        </div>
                        <a href="/" onclick="if(window.__lxG__consent__!==undefined&amp;&amp;window.__lxG__consent__.getState()!==null){window.__lxG__consent__.showConsent()} else {alert('This function only for users from European Economic Area (EEA)')}; return false">Change privacy settings</a>
                      </div>
                    </div>
                  </div>
                </div>
              </Media>
              <Media lessThan="md">
                <div className={styles.footerMobile}>              
                  <div className={styles.footerContactIcon}>
                    <div className="row">
                      <div className="logoContainer">
                        <img 
                          alt="logoLussoPack"
                          src={asset('/images/navigation/LussopackLogo.svg')}
                        />
                        <p className='footerLongText'>Lussopack is your premier destination for cutting-edge <b>luxury packaging solutions,</b> offering a wide range of <b>world class materials</b> and a comprehensive <b>Quality Management System (QMS)</b>. We specialize in crafting <b>high-quality, customizable packaging</b> designed to protect and enhance your precious products. With <b>sustainability as standard</b>, we offer eco-friendly packaging as options that would align with your green initiatives. Our professional team ensures precise, efficient packaging tailored to your needs. Discover the Lussopack difference - <b>where innovation meets sustainability,</b> and packaging becomes an extension of your brand.</p>
                      </div>

                      <div className="footer-social">
                        <h5 className="headline d-inline-block d-md-block mr-3">Contact us for more information</h5>
                        <div className='mb-4'>
                          <a href="https://page.line.me/290lqtxf?openQrModal=true" target="_blank"
                            rel="noopener noreferrer">
                            <Image src={asset('/images/footer/social/desktop/lineIcon.svg')} alt="line" width="40" height="40" className="footer-social-icon" />
                          </a>
                          <a href="https://api.whatsapp.com/message/5JQHETSSNKG5B1?autoload=1&app_absent=0" target="_blank"
                            rel="noopener noreferrer">                          
                            <Image src={asset('/images/footer/social/desktop/whatAppIcon.svg')} alt="whatApp" width="40" height="40" className="footer-social-icon" />
                          </a>
                          <a href="https://www.facebook.com/LussoPack/" target="_blank"
                            rel="noopener noreferrer">
                            <Image src={asset('/images/footer/social/desktop/facebookIcon.svg')} alt="facebook" width="40" height="40" className="footer-social-icon" />
                          </a>
                          <a href="https://www.instagram.com/lussopack/" target="_blank"
                            rel="noopener noreferrer">
                            <Image src={asset('/images/footer/social/desktop/igIcon.svg')} alt="ig" width="40" height="40" className="footer-social-icon" />
                          </a>
                          <a href="https://th.linkedin.com/company/lussopack" target="_blank"
                            rel="noopener noreferrer">
                            <Image src={asset('/images/footer/social/desktop/linkinIcon.svg')} alt="linkIn" width="40" height="40" className="footer-social-icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-0 mb-3" />

                  <div className={styles.footerContact}>                                    
                      <div className={styles.contactMemberOf}>                                          
                        <h5>Member of</h5>                                             
                        <div className='d-flex flex-row mb-4'>
                          <div id="Certificate-banners" className={`${styles.dbdBanner} mr-2`} width="108" height="108"></div>  
                          <a href={"https://www.bkkgems.com/"} rel="noopener noreferrer" target="_blank">
                          <img 
                            alt="memberOf1"
                            src={asset('/images/footer/contact/mobile/memberOf1.svg')}
                            width="108"
                            height="108"
                          />            
                          </a>       
                          <a href={"https://www.ieat.go.th/th/"} rel="noopener noreferrer"  target="_blank">     
                          <img 
                            alt="memberOf2"
                            src={asset('/images/footer/contact/mobile/memberOf2.svg')}
                            width="108"
                            height="108"
                          />     
                           </a>     
                        </div>
                        <div className='d-flex flex-row mb-4'>                              
                           <a className='mr-3' href={"https://www.ditp.go.th/"} rel="noopener noreferrer" target="_blank"> 
                            <img 
                              alt="memberOf3"
                              src={asset('/images/footer/contact/mobile/memberOf3.svg')}
                              width="108"
                              height="54"
                            />            
                           </a>     
                           <a href={"https://www.ditp.go.th/"} rel="noopener noreferrer" target="_blank"> 
                            <img 
                              alt="memberOf4"
                              src={asset('/images/footer/contact/mobile/memberOf4.svg')}
                              width="108"
                              height="54"
                            />            
                           </a>            
                        </div>
                        
                      </div>

                      <div className={styles.contactUs}>
                          <h5>Contact Us</h5>
                          <div className="row no-gutters">
                            <div className="col-12">
                              <div className={styles.contactUsList}>
                                <img 
                                  alt="contactTel"
                                  src={asset('/images/footer/contact/desktop/contactTel.svg')}
                                  width="20"
                                  height="20"
                                />
                                <p>
                                  {/* <a href="tel:+66922230744">(+66)2 233 0744</a>
                                  <br/>
                                  <a href="tel:+6622330745">(+66)2 233 0745</a> */}
                                  <a href="tel:+66950494747">(+66)95-049-4747</a>
                                </p>
                              </div>
                              <div className={styles.contactUsList}>
                                <img 
                                  alt="contactTel"
                                  src={asset('/images/footer/contact/desktop/contactMail.svg')}
                                  width="20"
                                  height="20"
                                />
                                <p>
                                  <a href="mailto:hello@lussopack.com">hello@lussopack.com</a>
                                </p>
                              </div>
                              <div className={styles.contactUsList}>
                                <img 
                                  alt="contactLocate"
                                  src={asset('/images/footer/contact/desktop/contactLocate.svg')}
                                  width="20"
                                  height="20"
                                />
                                <p>1086/9-10 2FL.,&nbsp;&nbsp;Charoenkrung Rd., Bangrak,&nbsp;&nbsp;Bangrak,&nbsp;&nbsp;Bangkok 10500 Thailand</p>
                              </div>
                            </div>
                          </div>
                      </div>              
                  </div>
                  <hr className="mt-0 mb-3" />

                  <div className={styles.footerMoreInfo}>   
                  <div className="container p-0">
                    <div className="row footer-links">
                      {FOOTER_LINKS.map((menu, menuIndex) => {
                        return (
                          <div className="col-12 col-md-3 mb-4 footer-links-item" key={menuIndex}>
                            <FooterCollapse title={menu.label} expanded={expandedLinks[menuIndex]} onToggle={e => this.onExpandedLinkToogle(e, menuIndex)}>
                              {(expandedLinks[menuIndex] ? menu.items : menu.items.slice(0, defaultLinksCount)).map((it, i) => (
                                <Link to={toRoute(it.url)} className="d-block" key={i}>{it.label}</Link>
                              ))}
                            </FooterCollapse>

                            <b><a href="#/" className="d-none d-md-block mt-2" onClick={e => this.onExpandedLinkToogle(e, menuIndex)}>{expandedLinks[menuIndex] ? 'Read Less[2]' : 'Read More[2]'}</a></b>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  </div>

                  <div className="footer-nav">
                    <div className="container py-3 py-md-0 footer-nav-container" style={{width: '80%'}}>
                      <div className="row align-items-md-center text-left">
                        <div className="col py-3">
                          <Link to={toRoute('/term-of-user')}>Terms and Conditions</Link>
                        </div>
                        <div className="col py-3" style={{textAlign:"right"}}>
                          <Link to={toRoute('/privacy')}>Privacy Policy</Link>
                        </div>
                        <div className="col-12 col-md py-3"  style={{textAlign:"center"}}>
                          <Link to={toRoute('/sitemap')}>Lussopack Sitemap</Link>
                        </div>
                        <div className="col-12 col-md py-3" style={{textAlign:"center"}}>
                          <Link to={'/!#'}>Copyright 2024 Asia Packaging Group Co.,Ltd</Link>
                        </div>
                        <div className="col-12 col-md py-3"  style={{textAlign:"center"}}>
                          <a href="/" onclick="if(window.__lxG__consent__!==undefined&amp;&amp;window.__lxG__consent__.getState()!==null){window.__lxG__consent__.showConsent()} else {alert('This function only for users from European Economic Area (EEA)')}; return false">Change privacy settings</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Media>
            </MediaContextProvider>
          </div>
    )
  }

}
